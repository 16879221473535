<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('agent.detail')"
    class="px-5 py-3"
    v-if="detail"
  >
    <v-col cols="12" class="text-right add--button">
      <router-link :to="`${basePathAgent}/${detail.uuid}`">
        <v-btn color="success" class="bottom--button mr-0">
          {{ $t('agent.btn.viewDetail') }}
        </v-btn>
      </router-link>
    </v-col>
    <v-row>
      <v-col cols="6">
        {{ $t('agent.uuid') }}
      </v-col>
      <v-col cols="6">
        {{ detail.uuid }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('agent.name') }}
      </v-col>
      <v-col cols="6">
        {{ detail.name }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('agent.phone') }}
      </v-col>
      <v-col cols="6">
        {{ detail.phone_number }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('agent.email') }}
      </v-col>
      <v-col cols="6">
        {{ detail.email }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"> Agen telah dihapus? </v-col>
      <v-col cols="6">
        {{ detail.deleted ? 'Ya' : 'Tidak' }}
      </v-col>
    </v-row>
    <div class="mt-6">
      {{ $t('general.bankInfo') }}
    </div>
    <hr class="my-2" />
    <v-row>
      <v-col cols="6">
        {{ $t('general.bankName') }}
      </v-col>
      <v-col cols="6">
        {{ detail.bank_info ? detail.bank_info.bank_name : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.accountHolder') }}
      </v-col>
      <v-col cols="6">
        {{ detail.bank_info ? detail.bank_info.account_holder : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('general.accountNumber') }}
      </v-col>
      <v-col cols="6">
        {{ detail.bank_info ? detail.bank_info.account_number : '' }}
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['detail', 'permissions', 'allowedActions'],
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.transaction.basePath,
      basePathAgent: (state) => state.belirumaUser.basePath,
    }),
  },
  methods: {},
};
</script>
